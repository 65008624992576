var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "newsDetail" },
    [
      _c("cms-text-link-block", [
        _c("div", {
          staticClass: "newsDetail__text",
          domProps: { innerHTML: _vm._s(_vm.news.text) },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "newsDetail__contentelements" },
        [
          _c("content-element-children", {
            attrs: { children: _vm.news.content.children },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }