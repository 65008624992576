





























import {AppStoreKeys} from '../../../../AppStoreKeys';
import CmsTextLinkBlock from '../../../../Component/Misc/Link/CmsTextLinkBlock/CmsTextLinkBlock.vue';
import {News} from '../../../../Interface/News';
import {JsonLdService} from '../../../../Service/JsonLdService';
import Headline from '../../Headline/Headline.vue';
import NewsAndEventsDetailMixin from '../NewsAndEventsDetailMixin';

export default {
    name: 'NewsDetail',
    mixins: [NewsAndEventsDetailMixin],
    components: {CmsTextLinkBlock, Headline},
    metaInfo()
    {
        return {
            title: this.ogTitle,
            meta: this.metaTags.concat([
                {'name': 'description', 'content': this.ogDescription, 'vmid': 't3-description'}
            ]),
            script: [
                JsonLdService.makeBreadcrumb(this.ogTitle),
                JsonLdService.makeBlogPost(this.ogTitle, this.ogDescription, this.ogImage)
            ]
        };
    },
    computed: {
        news(): News
        {
            return this.context.initialState.response.data;
        },
        ogTitle(): string
        {
            return this.getOgTitle(this.news.headline);
        },
        ogDescription(): string
        {
            return this.getOgDescription(this.news.text);
        },
        ogImage(): string
        {
            return this.getOgImage(this.news.images);
        }
    },
    created()
    {
        this.context.store.set(AppStoreKeys.PAGE_HEADLINE_OVERRIDE, this.news.headline);
        if (this.news.images) {
            this.context.store.set(AppStoreKeys.PAGE_TEASER_IMAGE_OVERRIDE, this.news.images);
        }
        this.context.pageContext.pageMeta.setCanonical(this.news.link);
    }
};
